import { models, helpers, utils } from '@kurtosys/ksys-app-template';
import { action, computed } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IGlobalInputsChangeDetail } from '../../../models/commonTypes';

export class InputStore extends StoreBase {

	@computed
	get eventBusHelper(): helpers.EventBusHelper {
		return this.storeContext.appStore.eventBusHelper;
	}

	@computed
	get globalInputIdentifiers(): string[] {
		return this.storeContext.appStore.globalInputIdentifiers;
	}

	public initialize(): Promise<void> {
		throw new Error('Method not implemented.');
	}

	public getStorageId(rawIdentifier: string) {
		return `${ rawIdentifier }-${ this.storeContext.appStore.currentUrlKey }`;
	}

	/**
	 * After query store has initialized we can proceed to update the inputs and start listening for future input updates.
	 */
	public async register() {
		// if we have configured global input ids, then we should start listening for them
		if (this.globalInputIdentifiers.length > 0) {
			this.storeContext.appStore.logDebug('Input Store > Register');
			// Start listening for global input app event updates
			this.eventBusHelper.register(models.eventBus.EventType.globalInputsChange, {
				handleEvent: async (customEvent: CustomEvent<IGlobalInputsChangeDetail>) => {
					const { identifier = '', storageType, values } = customEvent.detail;
					await this.handleUpdate(identifier, storageType, values);
				},
			});
		}
	}

	/**
	 * This method helps initialize the query store context with inputs based on prior selections in the associated global input apps.
	 */
	public async getAllFromStorage() {
		const identifiers = [];
		let storageValues = {};
		// build up one object of inputs for all inputs we are listening for
		for (const identifier of this.globalInputIdentifiers) {
			this.storeContext.appStore.logDebug('Input Store > Get All From Storage', identifier);
			const values = await this.getFromStorage(identifier);
			if (!utils.typeChecks.isNullOrEmpty(values)) {
				identifiers.push(identifier);
				storageValues = {
					...storageValues,
					...values,
				};
			}
		}
		this.cleanValues(storageValues);
		return storageValues;
	}

	/**
	 * Updates the query store's context with new inputs, thus re-firing requests
	 *
	 * @param identifiers
	 * @param values
	 */
	@action
	private async updateQueryInputs(identifiers: string[], values: Record<string, any>) {
		this.cleanValues(values);
		this.storeContext.appStore.logDebug('Input Store > Update Query Inputs', { identifiers, values });
		await this.storeContext.queryStore.updateInputs(values);
	}

	/**
	 * This method will search for global inputs values based on events received from a global input app, and trigger the updates
	 *
	 * @param identifier
	 * @param [storageType]
	 * @param [values]
	 * @returns
	 */
	private async handleUpdate(identifier: string, storageType?: models.eventBus.StorageType, values?: Record<string, any>) {
		// Is the current app listening for the current identifier?
		if (this.globalInputIdentifiers.includes(identifier)) {
			this.storeContext.appStore.logDebug('Input Store > Handle Event Update', { identifier, storageType, values });
			// sync values passed from the event
			if (values) {
				await this.updateQueryInputs([identifier], values);
			}
			// sync values from storage
			else {
				const storageValues = this.getFromStorage(identifier, storageType);
				if (storageValues) {
					await this.updateQueryInputs([identifier], storageValues);
				}
			}
		}
	}

	/**
	 * Get the global input out of storage, and apply fallback searches
	 *
	 * @param identifier
	 * @param [storageType=models.eventBus.StorageType.Session]
	 * @returns
	 */
	private getFromStorage(identifier: string, storageType: models.eventBus.StorageType = models.eventBus.StorageType.Session): Record<string, any> | undefined {
		let storage;
		switch (storageType) {
			case models.eventBus.StorageType.Local:
				storage = localStorage;
				break;
			case models.eventBus.StorageType.Session:
				storage = sessionStorage;
				break;
			default:
				return;
		}
		const storageValues = this.getStorageValue(storage, identifier);

		// As we do not know how the global input app is setup to store its values when initializing the current app, we have to look at the default session storage, and fallback to local storage.
		if (!storageValues && storageType === models.eventBus.StorageType.Session) {
			return this.getFromStorage(identifier, models.eventBus.StorageType.Local);
		}

		return storageValues;
	}

	/**
	 * Pull global input value out of the target storage type, and convert it into an object.
	 *
	 * @param storage
	 * @param rawIdentifier
	 * @returns
	 */
	private getStorageValue(storage: Storage, rawIdentifier: string): Record<string, any> | undefined {
		let storageValue = storage.getItem(this.getStorageId(rawIdentifier));
		if (!storageValue) {
			// fallback to legacy storage key where the URL was not considered
			storageValue = storage.getItem(rawIdentifier);
		}
		if (storageValue) {
			try {
				return JSON.parse(storageValue);
			}
			catch (e) {
				this.storeContext.appStore.logDebug('Input Store > Get Storage Value Error', e);
			}
		}
		return;
	}

	/**
	 * The global inputs app has the option to store an object with a label and value to help identify which item to pre-select
	 * This method parses the object and only returns the value to assist with input placeholders e.g. {input:date}
	 *
	 * @private
	 * @param [values]
	 */
	private cleanValues(values?: Record<string, any>) {
		if (values) {
			for (const key of Object.keys(values)) {
				if (values[key] && typeof values[key] === 'object' && values[key].label && values[key].value) {
					values[key] = values[key].value;
				}
			}
		}
	}
}