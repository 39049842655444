export function defineEventBusComponent() {
	if (customElements.get('ksys-event-bus') === undefined) {
		class KsysEventBus extends HTMLElement {
			constructor() {
				super();
				this.attachShadow({ mode: 'open' });
			}
		}
		customElements.define('ksys-event-bus', KsysEventBus);
	}
}
