import { Manifest } from '../configuration/Manifest';
const manifest = new Manifest();

// lazy load mock data to prevent it being loaded in with app prod builds
export async function getMockData() {
	if (manifest.isDevelopment) {
		const imported = await import('../configuration/mockData');
		const { MockData } = imported;
		return MockData;
	}
	return;
}