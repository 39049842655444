import React, { Component } from 'react';
import {
	ICallToActionProps,
	ICallToActionState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Button from '@kurtosys/ksys-app-components/dist/components/base/Button';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';

@inject('appStore', 'callToActionStore')
@observer
export class CallToAction extends Component<ICallToActionProps, ICallToActionState> {
	static configurationKey: 'callToAction' = 'callToAction';
	static styleKey: 'callToAction' = 'callToAction';
	constructor(props: ICallToActionProps) {
		super(props);
	}
	render() {
		const { callToActionStore, appStore } = this.props;
		if (!callToActionStore || !appStore || !appStore.hasSelectionsAndDisclaimers) {
			return null;
		}
		const { text, rejectText, order, disabled, handleClick, handleRejectClick } = callToActionStore;
		const rejectButton = (
			<Button
				onClick={ handleRejectClick }
				suppressAnalytics = { true }
			>
				<Translate>
					{ rejectText }
				</Translate>
			</Button>
		);
		const acceptButton = (
			<Button
				disabled={ disabled }
				onClick={ handleClick }
				analyticsContext = { appStore.selectedAttestationContext }
			>
				<Translate>
					{ text }
				</Translate>
			</Button>
		);
		return (
			<Wrapper>
				{ rejectText && order === 'rejectThenAccept' && rejectButton }
				{ acceptButton }
				{ rejectText && order === 'acceptThenReject' && rejectButton }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(CallToAction, 'callToAction');

