import { Manifest } from '../configuration/Manifest';
import { isDebug } from './isDebug';
const manifest = new Manifest();

export function logDebug(action: string, detail?: any, configKey?: string) {
	if (isDebug()) {
		const args = [`%c${ manifest.packageName }%c${ configKey ? `.${ configKey }` : '' }%c.${ action }`, 'color: #FF00FF; font-weight: bold; font-size: 1.3em;', 'color: #00FFDD; font-size: 1.1em', 'color: #FFFFFF; font-weight: normal; font-size: 0.9em; text-transform: uppercase;'];
		if (detail) {
			console.group(...args);
			console.log(detail);
			console.groupEnd();
		}
		else {
			console.log(...args);
		}
	}
}