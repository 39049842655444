import { Manifest } from './configuration/Manifest';
import { loadExternalScripts } from './utils/scripts';
import { TemplateStore } from './components/App/stores/TemplateStore';
import { IAppsHydration } from './models/app/IAppsHydration';
import { IAppResponse } from './models/commonTypes';
import { ITheme } from '@kurtosys/ksys-app-components/dist/models/ITheme';
import { defineEventBusComponent } from './ksys-event-bus';
const manifest = new Manifest();

export const HYDRATION_KEY = '__ksys-app-hydration__';
export const PREVIEW_CONTEXT_KEY = '__ksys-app-preview-context__';

export function getHydrationPoint(): IAppsHydration {
	if (!(window as any)[HYDRATION_KEY]) {
		(window as any)[HYDRATION_KEY] = {};
	}
	return (window as any)[HYDRATION_KEY];
}
export interface IPreviewContext extends Partial<IAppResponse> {
	themeKey?: string;
	theme?: ITheme;
}
export type TPreviewContext = IPreviewContext | undefined;
export function getPreviewContext(applicationCode = ''): TPreviewContext {
	if (window) {
		const keyWithApplicationCode = `${ PREVIEW_CONTEXT_KEY }${ applicationCode || '' }`;
		if ((window as any)[keyWithApplicationCode]) {
			return (window as any)[keyWithApplicationCode];
		}

		if (!(window as any)[PREVIEW_CONTEXT_KEY]) {
			(window as any)[PREVIEW_CONTEXT_KEY] = {};
		}
		return (window as any)[PREVIEW_CONTEXT_KEY];
	}
}

export const TEMPLATE_STORE = new TemplateStore(manifest);

async function initialize() {
	defineEventBusComponent();
	await TEMPLATE_STORE.initialize();
	loadExternalScripts(manifest, manifest.externalScripts, () => {
		require('./start');
	}, TEMPLATE_STORE.externalScriptsBasePath || manifest.getBaseUrl(''), TEMPLATE_STORE.cacheOptions);
}

initialize();