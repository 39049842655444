import { StoreBase } from '../../../common/StoreBase';
import { action, computed } from 'mobx';
import { models, utils } from '@kurtosys/ksys-app-template';
import { IAccessibilityValues } from '../../../models/accessibility/IAccessibilityValues';
import { IAccessibilityConfig } from '../../../models/accessibility/IAccessibilityConfig';

export class AccessibilityStore extends StoreBase {
	values: IAccessibilityValues = {};

	constructor(props: any) {
		super(props);
		this.accessibilityText = this.accessibilityText.bind(this);
	}

	@action
	async initialize(): Promise<void> {
		this.values = this.accessibilityValues;
	}

	@computed
	get appConfiguration() {
		return this.storeContext.appStore && this.storeContext.appStore.configuration;
	}

	@computed
	get accessibilityConfig(): IAccessibilityConfig {
		return (this.appConfiguration && this.appConfiguration.accessibilityConfig) || {};
	}

	@computed
	get accessibilityValues(): IAccessibilityValues {
		const config: IAccessibilityValues = this.orchestrateConfigKeys(this.accessibilityConfig);
		return config;
	}

	orchestrateConfigKeys(obj: {}) {
		const orchestratedObj: { [key: string]: string | {} | undefined } = {};
		const result = Object.entries<typeof obj>(obj).map((elem) => {
			const parentKey = elem[0];
			const configOption = elem[1];
			const configOptionKeys = Object.keys(configOption);
			const retObj: { [key: string]: string | {} | undefined } = {};

			if (configOptionKeys.includes('query') || configOptionKeys.includes('value')) {
				retObj[parentKey] = this.getOptionQueryOrValue(configOption);
			}
			else {
				retObj[parentKey] = this.orchestrateConfigKeys(configOption);
			}

			return retObj;
		});

		for (const elem of result) {
			Object.assign(orchestratedObj, elem);
		}

		return orchestratedObj;
	}

	getOptionQueryOrValue(configOption: models.query.IQueryOrValue): string | undefined {
		if (configOption) {
			if (configOption.query) {
				return this.getQueryValue(configOption.query);
			}
			if (configOption.value) {
				return configOption.value;
			}
			return undefined;
		}
	}

	accessibilityText = (key: string, defaultValue: string) => {
		if (utils.typeChecks.isNullOrEmpty(this.values)) {
			this.initialize();
		}
		if (typeof key === 'string' && !key.startsWith('asset') && !key.startsWith('charting')) {
			key = 'asset.' + key;
		}
		return utils.object.getValueFromDotNotation(key, this.values, defaultValue);
	}
}
